import { findIsChildOfBundleInOrder } from '@/lib/graphql/transformers/order/order';
import { OPERATOR } from '../orders';
import { getDiscountAmount, priceToNumber } from '../price';
import { isBundle } from '../product';
import { getRealURL, isNumber } from '../utils';
import { AFFILIATION_NAME_OPERATOR, getProductCategoriesTree } from './helpers';

import type { TypeGTMProduct, TypeMetaDatas } from '@/types';

export const getPrices = (
  key: string,
  items: any[],
  metaDataKey: 'metaData' | 'extraData',
) => {
  let price = 0;
  let priceNoTax = 0;
  let regularPrice = 0;

  items.forEach((item) => {
    const metaData = item.node[metaDataKey] as TypeMetaDatas;
    const childOfBundle = metaData?.find(findIsChildOfBundleInOrder);

    if (childOfBundle?.value === key) {
      const product = item.node.product.node;
      const total = isNumber(item.node.total)
        ? parseFloat(item.node.total ?? '0')
        : priceToNumber(item.node.total ?? '0');
      const totalTax = isNumber(item.node.totalTax)
        ? parseFloat(item.node.totalTax ?? '')
        : priceToNumber(item.node.totalTax ?? '0');
      const priceItem = parseFloat((total + totalTax).toFixed(2));

      price += priceItem;
      priceNoTax += parseFloat(parseFloat(item.node.total).toFixed(2));
      regularPrice += priceToNumber(product.regularPrice) * item.node.quantity;
    }
  });

  const discount = parseFloat((regularPrice - price).toFixed(2));

  return {
    price: parseFloat(price.toFixed(2)),
    price_no_tax: priceNoTax,
    discount: discount > 0 ? discount : null,
  };
};

export const getID = (
  key: string,
  items: any[],
  metaDataKey: 'metaData' | 'extraData',
): string => {
  const id: string[] = [];

  items.forEach((item) => {
    const metaData = item.node[metaDataKey] as TypeMetaDatas;
    const childOfBundle = metaData?.find(findIsChildOfBundleInOrder);
    const sku = item.node.variation
      ? item.node.variation.node.sku
      : item.node.product.node.sku;
    const quantity = item.node.quantity;

    if (childOfBundle?.value === key) {
      id.push(`${sku}/${quantity}`);
    }
  });

  return id.join(',');
};

export const normalizeOrderItems = (items: any[]) => {
  const products: Array<TypeGTMProduct> = [];

  items.forEach((item, index) => {
    const metaData = item.node.metaData as TypeMetaDatas;
    const childOfBundle = metaData?.find(findIsChildOfBundleInOrder);
    const bundleParentKey = metaData?.find(
      (meta) => meta.key === 'cart_item_key',
    );
    const boxVariantQuantity = metaData?.find(
      (meta) => meta.key === 'woosb_variant_quantity',
    );
    const autoCalculatePrice = item.node.product.node.autoCalculatePrice;
    const isCustomBoxParent =
      isBundle(item.node.product.node.type) && autoCalculatePrice;
    const isCustomBoxChild =
      childOfBundle && boxVariantQuantity && !autoCalculatePrice;
    const productVariation = item.node.variation;
    const product = productVariation
      ? productVariation.node
      : item.node.product.node;
    const onSale = item.node.product.node.onSale;
    const discount = onSale
      ? Number.parseFloat(
          getDiscountAmount(
            priceToNumber(product.regularPrice),
            priceToNumber(product.salePrice),
          ).toFixed(2),
        )
      : null;

    const brand =
      item.node.product.node.caracteriSticasDelProducto.datosDeProducto?.marca;
    const seller =
      product.sellerInfo.name === OPERATOR
        ? AFFILIATION_NAME_OPERATOR
        : product.sellerInfo.name;
    const quantity = item.node.quantity;
    const image = item.node.product.node.image?.sourceUrl ?? null;
    const url = item.node.product.node?.link
      ? getRealURL(item.node.product.node.link)
      : null;

    let discountGTM = onSale && discount ? discount * quantity : null;
    let price = priceToNumber(product.regularPrice) * quantity;
    let priceNoTax = parseFloat(parseFloat(item.node.total).toFixed(2));

    if (isCustomBoxParent && bundleParentKey) {
      const prices = getPrices(bundleParentKey.value, items, 'metaData');
      price = prices.price;
      priceNoTax = prices.price_no_tax;
      discountGTM = prices.discount;
    }

    if (isCustomBoxChild) {
      price = 0;
      priceNoTax = 0;
      discountGTM = 0;
    }

    let itemToAdd: TypeGTMProduct = {
      item_id: product.sku,
      item_name: product.name,
      affiliation: seller,
      coupon: null,
      currency: 'EUR',
      discount: discountGTM,
      index,
      item_brand: Array.isArray(brand) ? brand[0]?.slug : null,
      item_list_id: null,
      item_list_name: null,
      item_variant: productVariation
        ? product.sku
        : autoCalculatePrice && !childOfBundle
          ? boxVariantQuantity?.value
          : null,
      price,
      price_no_tax: priceNoTax,
      quantity,
      image,
      url,
      ...(isCustomBoxChild && {
        custom_box: true,
        ...(boxVariantQuantity && {
          custom_box_variant: boxVariantQuantity.value,
        }),
      }),
      ...(isCustomBoxParent &&
        bundleParentKey && {
          custom_box_items: getID(bundleParentKey.value, items, 'metaData'),
        }),
    };

    if (item.node.product.node.productCategories) {
      const categories = getProductCategoriesTree(item.node.product.node);

      itemToAdd = {
        ...itemToAdd,
        ...categories,
      };
    }

    products.push(itemToAdd);
  });

  return products;
};
