export const routes = {
  index: '/',
  cart: '/cesta',
  checkout: {
    datos: '/finalizar-compra',
    datosGuest: '/finalizar-compra/paso/datos',
    direccion: '/finalizar-compra/paso/direccion',
    gracias: '/finalizar-compra/paso/gracias',
    confirmation: '/finalizar-compra/paso/confirmacion',
  },
  micuenta: {
    index: '/mi-cuenta',
    otrosPedidos: {
      index: '/mi-cuenta/otros-pedidos',
      order: (id: number | string) => `/mi-cuenta/otros-pedidos/${id}`,
    },
    editAccount: '/mi-cuenta/editar-cuenta',
    changePassword: '/mi-cuenta/cambiar-contrasena',
    address: '/mi-cuenta/mis-direcciones',
    paymentMethods: '/mi-cuenta/formas-de-pago',
    purchases: {
      index: '/mi-cuenta/mis-compras',
      order: (id: number | string) => `/mi-cuenta/mis-compras/${id}`,
    },
    myExperiences: '/mi-cuenta/mis-experiencias',
    mySubscriptions: {
      index: '/mi-cuenta/mis-suscripciones',
      order: (id: number | string) => `/mi-cuenta/mis-suscripciones/${id}`,
    },
    contact: '/mi-cuenta/contacto',
  },
  contact: '/contacto',
  faq: '/faqs',
  legalNoticePrivacyPolicy: '/aviso-legal-politica-privacidad',
  privacyPolicy: '/politica-privacidad',
  cookiesPolicy: '/politica-de-cookies',
  condicionesCompra: '/condiciones-generales-compra-move',
  condicionesUso: '/condiciones-uso-marketplace',
  joinBigCrafters: '/unete-bigcrafters',
  about: '/sobre-nosotros',
  aceleracionArtesanos: '/programa-aceleracion-digital-artesanos',
  sellers: {
    seller: (id: number | string) => `/vendedores/${id}`,
  },
  brand: (id: number | string) => `/marcas/${id}`,
  product: (id: number | string) => `/productos/${id}`,
};
