import type { TypeMetaDatas } from '@/types';
import type { TypeCartProductGraphQL } from '../types';
import type { TypeProductNodeGQL } from './order/types';

export const customTagTransformer = (
  product: TypeProductNodeGQL | TypeCartProductGraphQL,
  meta: TypeMetaDatas,
) => {
  if (product.customTemplate === 'custom-tag') {
    const newProduct = structuredClone(product);
    const tag = meta?.find((data) => data.key === 'etiqueta');
    const tagLabel = meta?.find((data) => data.key === 'etiquetalabel');
    const tagImage = meta?.find((data) => data.key === 'etiquetacheckoutimage');
    const message = tag
      ? [...(tag?.value?.split(' // ') ?? ' // '), tagLabel?.value]
          .filter(Boolean)
          .join(', ')
      : undefined;

    if (tagImage) {
      if (!newProduct.image) newProduct.image = { sourceUrl: tagImage.value };
      else newProduct.image.sourceUrl = tagImage.value;
    }

    if (message) newProduct.description = `Etiqueta: ${message}`;

    return newProduct;
  }

  return product;
};
