import dynamic from 'next/dynamic';

import { Button } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeErrorProps } from './types';

const Oops = dynamic(() => import('@/assets/images/Oops.svg'), { ssr: false });

/**
 * Error
 */
export const Error: FunctionComponent<TypeErrorProps> = ({
  className = '',
  title,
  children,
  link,
}: TypeErrorProps) => (
  <div
    className={`flex w-full flex-col flex-wrap justify-between sm:flex-row ${className}`}
  >
    <div className="order-2 flex basis-1/2 flex-col items-start sm:order-1 sm:justify-center">
      <div className="m-auto flex max-w-[370px] flex-col gap-4 text-center sm:m-0 sm:text-left">
        {title && <h1 className="u-headline u-headline--h1">{title}</h1>}

        {children && (
          <p className="u-body u-body--s max-w-[360px] sm:max-w-max">
            {children}
          </p>
        )}

        {link && (
          <Button
            as="a"
            href={link.href}
            label={link.label}
            className="m-auto w-full !max-w-full sm:m-0 sm:mr-auto sm:w-auto sm:max-w-max"
            size="normal"
            {...(link.onClick && { onClick: link.onClick })}
          />
        )}
      </div>
    </div>

    <div className="order-1 flex basis-1/2 items-center justify-center sm:order-2 sm:justify-end">
      <Oops
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className="max-w-[155px] sm:max-w-max"
      />
    </div>
  </div>
);

Error.displayName = 'Error';
