import { gql } from '@apollo/client';

import APClient from '@/lib/graphql/client';
import { routes } from '@/lib/routes';

import type { ReactNode } from 'react';

/**
 * @deprecated The method should not be used, use instead toArray
 */
export const childrenToArray = (
  children: ReactNode | ReactNode[],
): ReactNode[] => (Array.isArray(children) ? children : [children]);

/**
 * Check if the given value is an array and if not convert it to an array
 * @param {T} items The value to convert to an array
 * @returns {T[]} The resulting array
 **/
export const toArray = <T,>(items: T | T[]) =>
  Array.isArray(items) ? items : [items];

/**
 * TS fails to understand guard for Array.prototype.pop(), so Non-null
 * assertion operator is used to assert that this operand is non-null
 * @see https://github.com/microsoft/TypeScript/issues/30406
 * @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html#non-null-assertion-operator
 **/
export const getUrlExtension = (url: string): string => {
  const rgx = new RegExp(/[#?]/);
  const urlSplitted = url?.split(rgx)?.[0];

  if (!urlSplitted) return url;

  return urlSplitted.split('.').pop()!.trim();
};

export const isPNG = (extension: string): boolean =>
  extension?.toLowerCase() === 'png';

export const removeBaseURL = (url: string): string =>
  url.replace(process.env.NEXT_PUBLIC_BACK_URL ?? '', '');

export const getRealURL = (url?: string): string =>
  url?.includes(process.env.NEXT_PUBLIC_BACK_URL ?? '')
    ? url.replace(
        process.env.NEXT_PUBLIC_BACK_URL ?? '',
        process.env.NEXT_PUBLIC_FRONT_URL ?? '',
      )
    : `${process.env.NEXT_PUBLIC_FRONT_URL ?? ''}${
        url?.endsWith('/') ? url?.slice(0, -1) : url
      }`;

export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production';

export const debug = (message?: any, ...optionalParams: any[]): void => {
  // eslint-disable-next-line no-console
  if (!isProduction()) console.log(message, ...optionalParams);
};

/**
 * Determine if an object is empty or all values of an object are `null` or `''`
 * @param  {object}   object        Object to check
 * @return {boolean}                Comparison value
 */
export const isEmptyObject = (object: object): boolean =>
  Object.keys(object).length === 0 &&
  object.constructor === Object &&
  Object.values(object).every((x) => x === null || x === '');

/**
 * Transform a constant case string (IN_STOCK) to a lower case (instock)
 * @param  {string}   string        String to convert
 * @return {string}                 String converted
 */
export const constantCaseToLowerCase = (string: string): string =>
  (string || '').replaceAll('_', '').toLocaleLowerCase();

export const isCartRoute = (pathname: string) => pathname === routes.cart;
export const isMyAccountRoute = (pathname: string) =>
  pathname.includes(routes.micuenta.index);

export const formatDateShort = (date: string | Date) =>
  new Date(date).toLocaleString('es-ES', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });

/**
 * Format a date interval in a more human-like language
 * @param {string | Date} startDate
 * @param {string | Date} endDate
 * @returns string with the date formatted
 */
export const formatDateInterval = (
  startDate: string | Date,
  endDate: string | Date,
): string => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const init = new Date(startDate);
  const end = new Date(endDate);

  return `${init.getDate()} ${
    init.getMonth() !== end.getMonth() ? ` de ${months[init.getMonth()]}` : ``
  } al ${end.getDate()} de ${months[end.getMonth()]}`;
};

export const formartTimeOfSubscription = (
  timeOfSubscription: string,
): string => {
  switch (timeOfSubscription) {
    case '2_week':
      return 'Cada 2 semanas';
    case '4_week':
      return 'Cada 4 semanas';
    case '6_week':
      return 'Cada 6 semanas';
    case '8_week':
      return 'Cada 8 semanas';
    default:
      return 'Cada 4 semanas';
  }
};

export const getAppliedFiltersToURL = async (params: string[]) => {
  const realUrl: string[] = [];
  let filterString = '';
  let realCategory = '';
  let filterSlug = '';
  let filterValue = '';

  const { data } = await APClient.query({
    query: gql`
      query FiltersQueryUrl {
        filtrableFields {
          seo
        }
      }
    `,
  });

  const URLParams: string[] = structuredClone(params);

  if (isNumber(URLParams.at(-1))) {
    URLParams.pop();
  }

  URLParams.forEach((item: any) => {
    const found = data.filtrableFields?.find((field: any) => {
      const testString = `${field.seo}-`;
      return item.startsWith(testString);
    });

    if (!found) {
      realCategory = item;
      realUrl.push(item);
    } else {
      filterSlug = found.seo;
      filterValue = item.replace(`${found.seo}-`, '');
      filterString = `${filterString} AND ${found.seo}:"${item.replace(
        `${found.seo}-`,
        '',
      )}"`;
    }
  });

  return {
    arrayOfCategories: realUrl,
    algoliaFilter: filterString,
    wordpressSlug: realCategory,
    filterSlug,
    filterValue,
    url: `/${URLParams.join('/')}`,
  };
};

export const getPagePropsForAppliedFiltersToURL = (
  pageProps: any,
  url: string,
) => {
  const { customSeo, ...wantedProps } = pageProps;
  const newPageProps = structuredClone(wantedProps);

  if (customSeo?.customFields?.miraklfilterSeo?.titleSeo) {
    newPageProps.seo.title = customSeo.customFields.miraklfilterSeo.titleSeo;
  } else if (customSeo?.titleSeo) {
    newPageProps.seo.title = customSeo.titleSeo;
  }

  if (customSeo?.customFields?.miraklfilterSeo?.descriptionSeo) {
    newPageProps.seo.metaDesc =
      customSeo.customFields.miraklfilterSeo.descriptionSeo;
  } else if (customSeo?.descriptionSeo) {
    newPageProps.seo.metaDesc = customSeo.descriptionSeo;
  }

  newPageProps.seo.canonical = `${process.env.NEXT_PUBLIC_FRONT_URL?.replace(
    /\/$/,
    '',
  )}${url}`;

  if (customSeo?.customFields?.miraklfilterSeo?.flexibleContent) {
    const updateTypeFlexible = (value: string) =>
      value.replace(
        'FilterSEO_Miraklfilterseo',
        'ProductCategory_Contenidoflexible',
      );

    newPageProps.flexibleContent.flexible =
      customSeo?.customFields?.miraklfilterSeo?.flexibleContent
        .filter((flexible: any) => flexible.fieldGroupName !== undefined)
        .map((flexible: any) => ({
          ...flexible,
          fieldGroupName: updateTypeFlexible(flexible.fieldGroupName),
        }));
  }

  return newPageProps;
};

export const kebabCase = (str: string) =>
  str
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/([a-z])([A-Z])/g, '$1-$2')
    ?.replace(/\s+/g, '-')
    ?.toLowerCase();

/**
 * Same behaviour as {@link https://lodash.com/docs/4.17.15#debounce lodash-es/debounce} but returns a Promise.
 * @param fn The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @returns Returns the new debounced promise function.
 */
export const debounceAsync = (
  fn: (...args: any[]) => Promise<any>,
  wait: number = 0,
) => {
  let timeOut: NodeJS.Timeout;
  let stack: any[] = [];
  return (...args: any[]): Promise<any> => {
    clearTimeout(timeOut);
    return new Promise((resolve, reject) => {
      timeOut = setTimeout(() => {
        const currentStack = [...stack];
        stack = [];
        fn(...args)
          .then((data) => {
            currentStack.forEach(({ resolve }) => resolve(data));
          })
          .catch((data) => {
            currentStack.forEach(({ reject }) => reject(data));
          });
      }, wait);
      stack.push({ resolve, reject });
    });
  };
};

/**
 * setTimeout as a promise
 * @param  {number}  ms Time in milliseconds
 * @return {Promise}    Promise that resolves after the given time
 */
export const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Check if the given value is a number (type string or number)
 * @param  {T}       value Value to check
 * @return {boolean}       True if the value can be parsed as a number and is not empty
 */
export const isNumber = <T,>(value: T): value is NonNullable<T> =>
  value !== '' &&
  typeof value !== 'object' &&
  typeof value !== 'boolean' &&
  !Number.isNaN(Number(value));

/**
 * Check if the value is 'on'
 * @param value A string to convert to boolean
 * @returns Returns true if the value is 'on'
 *
 * @example
 * isOn('on') // true
 * isOn('off') // false
 * isOn('') // false
 */
export const isOn = (value: string | null | undefined): boolean =>
  value === 'on';

/**
 * Get items that only occur in the left array,
 * using the compareFunction to determine equality.
 * @param left Array of items to compare
 * @param right Array of items to compare
 * @param compareFunction Function to compare items
 * @returns Returns the items that only occur in the left array
 */
export const onlyInLeft = <T, Y>(
  left: T[],
  right: Y[],
  compareFunction: (a: T, b: Y) => boolean,
) =>
  left.filter(
    (leftValue) =>
      !right.some((rightValue) => compareFunction(leftValue, rightValue)),
  );

const isObject = <T,>(value: T): value is T & Record<'string', unknown> =>
  typeof value === 'object' && value !== null;

const removePropertyMutate = <T extends object>(
  object: T,
  propToRemove: string,
) => {
  for (const property in object) {
    const value = object[property];

    if (property === propToRemove) delete object[property];
    else if (isObject(value)) removePropertyMutate(value, propToRemove);
  }

  return object;
};

/**
 * Remove a property from an object and all its nested objects
 * @param object Object to remove the property from
 * @param propToRemove Property to remove
 * @returns Returns the object without the property
 * @example
 * const obj = { a: 1, b: { c: 2, d: 3 } };
 * removeProperty(obj, 'c');
 * // obj = { a: 1, b: { d: 3 } };
 */
export const removeProperty = <T extends object>(
  object: T,
  propToRemove: string,
) => removePropertyMutate(structuredClone(object), propToRemove);
