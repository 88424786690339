import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { ConditionalWrapper } from '@/hoc';
import { BackgroundImage, NextLink } from '@/atoms';

import { removeBaseURL, styleInjector } from '@/lib/utils';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { TypeContainerProps } from './types';

/**
 * El componente Container es totalmente flexible y permite casi cualquier cosa
 */
const BaseContainer = <T extends ElementType = 'div'>(
  {
    as,
    className = '',
    classNameWrapper = '',
    wrapperSize = '',
    wrapper = false,
    backgroundTop,
    backgroundBottom,
    backgroundImage = '',
    backgroundLazy = true,
    backgroundImageLink,
    overlay = 0,
    backgroundColor = 'bg-transparent',
    padding = 'px-4 md:py-8 py-4',
    justify = '',
    align = '',
    textAlign = '',
    zIndex = 1,
    children,
    notLazy = true,
    ...props
  }: TypeContainerProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const Component = as || 'div';

  return (
    <Component
      className={oneLine`
        relative flex flex-col
        ${justify}
        ${align}
        ${padding}
        ${textAlign}
        ${className}
        ${backgroundTop || backgroundBottom ? '' : backgroundColor}
      `}
      ref={ref}
      {...props}
    >
      {!(backgroundTop || backgroundBottom) && (
        <ConditionalWrapper
          condition={Boolean(backgroundImageLink && backgroundImageLink?.url)}
          wrapper={(children) => (
            <NextLink
              href={{
                pathname: removeBaseURL(backgroundImageLink?.url ?? ''),
              }}
            >
              <a target={backgroundImageLink?.target}>{children}</a>
            </NextLink>
          )}
        >
          <BackgroundImage
            backgroundImage={backgroundImage}
            overlay={overlay}
            notLazy={backgroundLazy || notLazy}
          />
        </ConditionalWrapper>
      )}

      {backgroundTop && (
        <div
          className="absolute left-0 right-0 top-0 h-1/2"
          style={{ backgroundColor: backgroundTop.backgroundColor }}
        >
          <ConditionalWrapper
            condition={Boolean(backgroundImageLink && backgroundImageLink?.url)}
            wrapper={(children) => (
              <NextLink
                href={{
                  pathname: removeBaseURL(backgroundImageLink?.url ?? ''),
                }}
              >
                <a target={backgroundImageLink?.target}>{children}</a>
              </NextLink>
            )}
          >
            <BackgroundImage
              backgroundImage={backgroundTop.backgroundImage}
              overlay={backgroundTop.overlay}
              notLazy={backgroundLazy || notLazy}
            />
          </ConditionalWrapper>
        </div>
      )}

      {wrapper ? (
        <div
          className={`u-wrapper ${wrapperSize} flex flex-col ${justify} ${align} ${textAlign} ${classNameWrapper}`}
          style={{ zIndex: zIndex }}
        >
          {children}
        </div>
      ) : children && (backgroundBottom || backgroundImage || backgroundTop) ? (
        styleInjector({ zIndex: zIndex }, children)
      ) : (
        children
      )}

      {backgroundBottom && (
        <div
          className="absolute bottom-0 left-0 right-0 h-1/2"
          style={{ backgroundColor: backgroundBottom.backgroundColor }}
        >
          <ConditionalWrapper
            condition={Boolean(backgroundImageLink && backgroundImageLink?.url)}
            wrapper={(children) => (
              <NextLink
                href={{
                  pathname: removeBaseURL(backgroundImageLink?.url ?? ''),
                }}
              >
                <a target={backgroundImageLink?.target}>{children}</a>
              </NextLink>
            )}
          >
            <BackgroundImage
              backgroundImage={backgroundBottom.backgroundImage}
              overlay={backgroundBottom.overlay}
              notLazy={backgroundLazy || notLazy}
            />
          </ConditionalWrapper>
        </div>
      )}
    </Component>
  );
};

export const Container = forwardRef(BaseContainer);
