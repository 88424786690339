import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { Spinner } from '@/icons';

import '@/lib/utils/forwardRef';
import styles from './Button.module.css';

import type { PolymorphicRef } from '@/types';
import type { ElementType } from 'react';
import type { ButtonProps } from './types';

/**
 * Primary UI component for user interaction
 */
const ButtonBase = <T extends ElementType = 'button'>(
  {
    className = '',
    as,
    style = 'primary',
    variant = 'primary',
    disabled = false,
    size = 'normal',
    negative = false,
    icon = null,
    label,
    loading = false,
    children,
    ...props
  }: ButtonProps<T>,
  ref: PolymorphicRef<T>,
) => {
  const HTMLTag = as || 'button';

  return (
    <HTMLTag
      className={oneLine`
        ${styles['o-button']} 
        ${styles[`o-button--${size}`]}
        ${
          styles[
            `o-button--${variant !== 'primary' ? variant : style}${
              negative ? '-negative' : ''
            }`
          ]
        }
        ${disabled ? styles['o-button--disabled'] : ''}
        ${className}
      `}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {loading ? (
        <Spinner className="animate-spin motion-reduce:hidden" />
      ) : (
        <>
          {label ?? children}
          {icon}
        </>
      )}
    </HTMLTag>
  );
};

export const Button = forwardRef(ButtonBase);
