import { Children, isValidElement } from 'react';

import type {
  ElementType,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react';

export const hasChild = (
  children: ReactNode | undefined,
  child: ElementType,
): boolean => {
  const types = Children.map(children, (item) => {
    if (!isValidElement(item)) return null;
    return item.type;
  });

  return (types || []).includes(child);
};

export const pickChild = (
  children: ReactNode | undefined,
  targetChild: ElementType,
): [ReactNode | undefined, ReactElement | undefined] => {
  let target: ReactNode = null;
  const withoutTargetChildren = Children.map(children, (item) => {
    if (!isValidElement(item)) return item;
    if (item.type === targetChild) {
      target = item;
      return null;
    }
    return item;
  });

  const targetChildren = target !== null ? target : undefined;

  return [withoutTargetChildren, targetChildren];
};

export const pickChilds = (
  children: ReactNode | undefined,
  targetChild: ElementType,
): [ReactNode | undefined, ReactElement[] | undefined] => {
  const target: ReactElement[] = [];
  const withoutTargetChildren = Children.map(children, (item) => {
    if (!isValidElement(item)) return item;
    if (item.type === targetChild) {
      target.push(item);
      return null;
    }
    return item;
  });

  const targetChildren = target.length > 0 ? target : undefined;

  return [withoutTargetChildren, targetChildren];
};

/**
 * For the correct operation of this function, all children must
 * allow the prop className
 */
export const classInjector = (
  className = '',
  children: ReactNode | ReactNode[],
): ReactNode[] | ReactNode => {
  const StyledChildren = (): ReactNode[] | ReactNode =>
    Children.map(
      // TODO Check types
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      children,
      (
        child: ReactElement<any, string | JSXElementConstructor<any>> | string,
      ) =>
        child && typeof child !== 'string' ? (
          // TODO Check types
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          <child.type
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            {...child.props}
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            className={`${child.props.className ?? ''} ${className}`}
          />
        ) : (
          child
        ),
    );

  // TODO Check types
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return <StyledChildren />;
};

export const styleInjector = (
  style = {},
  children: ReactNode | ReactNode[],
): ReactNode[] | ReactNode => {
  const StyledChildren = (): ReactNode[] | ReactNode =>
    Children.map(
      // TODO Check types
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      children,
      (
        child: ReactElement<any, string | JSXElementConstructor<any>> | string,
      ) =>
        child && typeof child !== 'string' ? (
          // TODO Check types
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          <child.type
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            {...child.props}
            // TODO Check types
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            style={{ ...(child.props.style ?? {}), ...style }}
          />
        ) : (
          child
        ),
    );

  // TODO Check types
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return <StyledChildren />;
};
