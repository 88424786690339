import 'scripts/wdyr';
import '../styles/globals.css';
import { useEffect, useMemo, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ApolloProvider } from '@apollo/client';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import Cookies from 'universal-cookie';

import { Container } from '@/atoms';
import { AgeGate, ErrorBoundary } from '@/molecules';
import { Error } from '@/pages';

import { LoginComponent } from '@/components/passwordProtect/LoginComponent';
import {
  CartProvider,
  InitPropsProvider,
  ToastProvider,
} from '@/components/providers';
import APClient from '@/lib/graphql/client';
import { routes } from '@/lib/routes';
import HeaderFromSeo from '@/lib/utils/seoHeader';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { NextRouter } from 'next/dist/client/router';
import type { ReactElement, ReactNode } from 'react';

declare global {
  interface Window {
    getInSiteForm(
      id: any,
      styleButton: any,
      styleBody: any,
      styleBox: any,
      styleBoxText: any,
      buttonValue: any,
      fuc: any,
      terminal: any,
      order: any,
      idioma: any,
      k: any,
    ): void;
    oct8ne: any;
    getInSiteForm(): void;
    userGigyaId: string;
    getExpirationMonthInput(a: string, b: string): void; // 👈️ turn off type checking
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps: { session, initialProps, pageProps, ...otherProps },
}: // TODO: Añadir tipos
AppPropsWithLayout<any>) {
  const cookies = useMemo(() => new Cookies(), []);
  const router = useRouter();
  const [verifiedAge, setVerifiedAge] = useState('undefined');

  useEffect(() => {
    if (verifiedAge === 'undefined' && cookies.get('ageVerification')) {
      setVerifiedAge(cookies.get('ageVerification'));
    }
  }, [cookies, verifiedAge]);

  useEffect(() => {
    const { cjevent } = router.query;

    if (cjevent) {
      fetch(`/api/cjevent/${cjevent}`);
    }
  }, [router]);

  const getLayout = Component.getLayout
    ? (children: ReactNode | ReactNode[]) =>
        Component.getLayout &&
        Component.getLayout({
          children: children,
          withCartInContent: true,
          ...pageProps,
          ...initialProps?.options?.ajustesGenerales,
          ...otherProps,
        })
    : (children: ReactNode | ReactNode[]) => children;

  return (
    <>
      <Script id="consent-mode-v2" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);}

          // Default ad_storage to 'denied'. 
          gtag('consent', 'default', { 
            'ad_storage': 'denied', 
            'analytics_storage': 'denied', 
            'personalization_storage': 'denied', 
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'wait_for_update': 500 
          });
        `}
      </Script>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>

      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_CBID}
        data-blockingmode="auto"
        strategy="afterInteractive"
      />

      <Oct8neScript router={router} pageProps={pageProps} />

      <Script src="/api/polyfills" strategy="beforeInteractive" />

      <HeaderFromSeo seo={pageProps?.seo} />

      <ErrorBoundary fallbackComponent={error('min-h-screen min-w-[100vw]')}>
        <InitPropsProvider.Provider value={initialProps}>
          <SessionProvider session={session} refetchOnWindowFocus={false}>
            {/* TODO: GET TYPE FROM pageProps.initialProps  && pageProps.witCartContent */}
            <ApolloProvider client={APClient}>
              <ToastProvider>
                <CartProvider>
                  {getLayout(
                    <ErrorBoundary
                      fallbackComponent={error('min-h-full w-full')}
                    >
                      <Component
                        pageProps={pageProps}
                        {...initialProps}
                        {...otherProps}
                      />
                    </ErrorBoundary>,
                  )}
                </CartProvider>
              </ToastProvider>
            </ApolloProvider>
          </SessionProvider>
        </InitPropsProvider.Provider>

        {!cookies.get('ageVerification') && (
          <AgeGate
            onAccept={() => {
              cookies.set('ageVerification', true, { path: '/' });
              setVerifiedAge('true');
            }}
            logo={initialProps.options.ajustesGenerales.logo}
          />
        )}
      </ErrorBoundary>
    </>
  );
}

const error = (className: string) => (
  <Container
    backgroundColor="bg-bg-primary"
    justify="justify-center"
    align="items-center"
    {...(className && { className })}
    wrapper
    wrapperSize="max-w-[800px]"
  >
    <Error
      title="Oops! Algo ha salido mal..."
      link={{
        label: 'Recargar la web',
        href: routes.index,
        onClick: () => location.reload(),
      }}
    >
      Parece que hay un error.
      <br />
      Lo sentimos, no hemos podido encontrar la página que buscas.
    </Error>
  </Container>
);

const Oct8neScript = ({
  router,
  pageProps,
}: {
  router: NextRouter;
  pageProps: any;
}) => {
  useEffect(() => {
    const oct8neId = 'oct8ne-script';

    if (!document.getElementById(oct8neId)) {
      const oct8neScript = document.createElement('script');
      oct8neScript.id = oct8neId;
      oct8neScript.setAttribute('data-nscript', 'afterInteractive');
      oct8neScript.textContent = `
      ${
        router.asPath.includes('/producto/')
          ? `window.oct8ne.currentProduct = {
          id: "${pageProps?.databaseId}",
          thumbnail: "${pageProps?.image?.sourceUrl}"
        };`
          : `window.oct8ne.currentProduct = null`
      }
      `;
      document.body.appendChild(oct8neScript);
    }
    return () => {
      document.getElementById(oct8neId)?.remove();
    };
  }, [pageProps?.databaseId, pageProps?.image?.sourceUrl, router.asPath]);

  return (
    <Script id="oct8ne-script" strategy="afterInteractive">
      {`
        window.oct8ne = document.createElement("script");
        window.oct8ne.type = "text/javascript";
        window.oct8ne.src = (document.location.protocol == "https:" ? "https://" : "http://") + "static-eu.oct8ne.com/api/v2/oct8ne.js";
        window.oct8ne.server = "backoffice-eu.oct8ne.com/";
        window.oct8ne.async = true;
        window.oct8ne.license = "DE29009BD8D794FAC2053D223DB890E5";
        window.oct8ne.baseUrl = 'https://bigcrafters.com';
        window.oct8ne.checkoutUrl = 'https://bigcrafters.com/finalizar-compra';
        window.oct8ne.loginUrl = 'https://bigcrafters.com/mi-cuenta';
        window.oct8ne.locale = "es-ES";
        window.oct8ne.currencyCode = "EUR";
        window.oct8ne.platform = "wordpress";
        ${
          router.asPath.includes('/producto/')
            ? `window.oct8ne.currentProduct = {
                id: "${pageProps?.databaseId}",
                thumbnail: "${pageProps?.image?.sourceUrl}"
              };`
            : `window.oct8ne.currentProduct = null`
        }
        window.oct8ne.customData = {
            importeCesta: null,
            categoriasProductosCesta: null,
            page: "${router.basePath}",
            productCategory: null,
            productPrice: 12,
            productSeller: null
        }
        window.s = document.getElementsByTagName("script")[0];
        insertOct8ne();
        function insertOct8ne() {
          window.s.parentNode.insertBefore(oct8ne, s);
        }`}
    </Script>
  );
};

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponent: LoginComponent,
      loginComponentProps: {
        buttonBackgroundColor: '#f68217',
        logo: 'https://media-pre.bigcrafters.com/wp-content/uploads/2022/06/29135115/bigcrafters.png',
      },
    })
  : MyApp;
