import { Fragment } from 'react';
import Image from 'next/image';
import { Portal } from '@headlessui/react';

import { imageTransformer } from '@/lib/graphql/transformers';
import { useFixSafariPortal } from '@/lib/hooks';

import type { FunctionComponent } from 'react';
import type { AgeGateProps } from './types';

export const AgeGate: FunctionComponent<AgeGateProps> = ({
  onAccept,
  onCancel,
  logo,
}: AgeGateProps): JSX.Element => {
  useFixSafariPortal();
  const logoImage = imageTransformer(logo);

  return (
    <Portal as={Fragment}>
      <div className="fixed left-0 top-0 z-[1000] flex h-full w-full flex-col items-center justify-center bg-cover">
        <div className="absolute left-0 top-0 h-full w-full bg-primary-900 opacity-80" />

        <div
          style={{ backgroundColor: '#F8F7F4' }}
          className="relative z-10 mx-6 flex w-80 flex-col items-center justify-center py-12 md:w-full md:max-w-[529px]"
        >
          <div className="relative h-16 w-60">
            <Image
              src={logoImage.src}
              alt={logoImage.alt}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
              draggable="false"
              priority={true}
            />
          </div>

          <p className="u-subtitle u-subtitle--l my-6 text-secondary-500">
            DISCULPA, PERO TENEMOS <br />
            QUE PREGUNTÁRTELO 😊
          </p>

          <p className="u-headline u-headline@mobile--h1 text-center md:text-[44px] md:leading-none">
            ¿ERES MAYOR DE EDAD?
          </p>

          <div className="relative my-8 flex text-black">
            <button
              className="u-subtitle u-subtitle--s mx-4 h-16 w-16 rounded-full border border-black sm:h-20 sm:w-20"
              onClick={onAccept}
            >
              SÍ
            </button>

            <a
              className="u-subtitle u-subtitle--s mx-4 inline-flex h-16 w-16 items-center justify-center rounded-full border border-black sm:h-20 sm:w-20"
              href="https://www.google.es"
              target="_blank"
              rel="noopener noreferrer"
              {...(onCancel && { onClick: onCancel })}
            >
              NO
            </a>
          </div>
        </div>
      </div>
    </Portal>
  );
};
