import { Component } from 'react';
import { withRouter } from 'next/router';

import { debug } from '@/lib/utils';

import type { ErrorInfo } from 'react';
import type { TypeErrorBoundaryState, TypeProps } from './types';

/**
 * ErrorBoundary
 */
class ErrorBoundaryClass extends Component<TypeProps, TypeErrorBoundaryState> {
  static displayName = 'ErrorBoundary';

  public state: TypeErrorBoundaryState = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): TypeErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Send error to Sentry
    debug('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryClass);

ErrorBoundary.displayName = 'ErrorBoundaryRouter';
