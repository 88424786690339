import { routes } from '@/lib/routes';
import {
  calculateDiscount,
  priceToNumber,
  SumCurrency,
  toCurrency,
} from '@/lib/utils';
import { customTagTransformer } from './custom-tag';
import { shippingEstimateDateTransformer } from './shipping-estimate-date';
import { getOrderID, getSellerName } from './utils';

import type { TypeBundle } from '@/components/providers';
import type { TypeMetaDatas } from '@/types';
import type {
  TypeCart,
  TypeCartBySellers,
  TypeCartContentsGraphQL,
  TypeCartGraphQL,
  TypeCartOffer,
  TypeCartOrders,
  TypeCartProductGraphQL,
  TypeOfPurchase,
  TypeShippingEstimateDate,
  TypeSubscriptionDataGraphQL,
} from '../types';

type TypeMiraklProduct = {
  selected_shipping_type: {
    label: string;
    delivery_date: string;
    code: string;
    minFreeAmount: string;
  };
  shipping_types: {
    code: string;
    total_shipping_price: number;
  }[];

  line_quantity: number;
  offer_id: number;
  offer_quantity: number;
  shop_name: string;
  shop_id: number;
};

const getTypeOfPurchase = (
  edges: TypeCartContentsGraphQL['edges'],
  subscriptionData?: TypeSubscriptionDataGraphQL,
): TypeOfPurchase => {
  const isOnlySuscribable =
    edges?.some((item: any) =>
      item?.node.product?.node.metaData?.some(
        (itemData: any) =>
          itemData.key === '_unique_in_suscription' && itemData.value === 'yes',
      ),
    ) ?? false;

  if (!subscriptionData) {
    return {
      isOnlySuscribable,
      uniquePrice: 0,
      subscriptionPrice: 0,
      totalDiscount: 0,
      onlySuscriptionPrice: 0,
      total: 0,
      discountCart: 0,
      isSubscription: isOnlySuscribable,
      withoutSubscription: 0,
    };
  }

  return {
    isOnlySuscribable,
    uniquePrice: subscriptionData.single_purchase,
    subscriptionPrice: subscriptionData.recurring_purchase,
    totalDiscount: subscriptionData.total_discount,
    onlySuscriptionPrice: edges
      .filter((edge) =>
        edge.node.product.node.metaData.some(
          (d) => d.key === '_non_subscribable' && d.value === 'no',
        ),
      )
      .reduce((acc, item) => acc + priceToNumber(item.node.total), 0),
    total: subscriptionData.total,
    discountCart: subscriptionData.discount_cart,
    isSubscription: subscriptionData?.hasSuscription
      ? subscriptionData.hasSuscription
        ? true
        : false
      : isOnlySuscribable,
    withoutSubscription: edges.filter((edge) =>
      edge.node.product.node.metaData.some(
        (d) => d.key === '_non_subscribable' && d.value === 'yes',
      ),
    ).length,
  };
};

const getOffer = (
  miraklProduct: TypeMiraklProduct | undefined,
  item: TypeCartContentsGraphQL['edges'][0]['node'],
): TypeCartOffer => {
  const productVariation = item.variation?.node;
  const product: TypeCartProductGraphQL = item.product.node;

  return {
    ...(miraklProduct
      ? {
          quantity: miraklProduct.line_quantity,
          id: miraklProduct.offer_id,
          stock: miraklProduct.offer_quantity,
        }
      : {
          quantity: item.quantity,
          id: item.key,
          stock: product.stockQuantity,
        }),
    subtotal: product.autoCalculatePrice ? toCurrency(0) : item.subtotal,
    subtotalTax: product.autoCalculatePrice ? toCurrency(0) : item.subtotalTax,
    tax: product.autoCalculatePrice ? toCurrency(0) : item.tax,
    total: product.autoCalculatePrice ? toCurrency(0) : item.total,
    extraData: item.extraData,
    key: item.key,
    variation: item.variation,
    product: {
      name: productVariation?.name ?? product.name,
      price: product.autoCalculatePrice
        ? toCurrency(0)
        : productVariation?.price ?? product.price,
      regularPrice: product.autoCalculatePrice
        ? toCurrency(0)
        : productVariation?.regularPrice ?? product.regularPrice,
      salePrice: product.autoCalculatePrice
        ? toCurrency(0)
        : productVariation?.salePrice ?? product.salePrice,
      image: productVariation?.featuredImage?.node ?? product.image,
      link: product.link,
      onSale: product.onSale,
      sku: productVariation?.sku ?? product.sku,
      productCategories: product.productCategories,
      databaseId: productVariation?.databaseId ?? product.databaseId,
      metaData: product.metaData,
      brand:
        product.caracteriSticasDelProducto.datosDeProducto?.marca?.at(0)
          ?.slug ?? undefined,
      description: product?.description,
    },
    ...(product.autoCalculatePrice && {
      bundleItems: [],
      bundleItemsAnalitycs: [],
    }),
  };
};

const getOrder = (
  miraklProduct: TypeMiraklProduct | undefined,
  item: TypeCartGraphQL['contents']['edges'][0]['node'],
  shippingEstimateDate: TypeShippingEstimateDate,
) => {
  const offer = getOffer(miraklProduct, item);

  return {
    shippingEstimateDate,
    total: priceToNumber(offer.subtotal) + priceToNumber(offer.subtotalTax), //TODO: Esto habria que cambiarlo en el back en algun momento
    offers: [offer],
  };
};

const order = (cart: TypeCart['cart']['contents']['cart']) => {
  const bigcrafters = cart.filter((s) => s.id === 0);
  const otherSellers = cart.filter((s) => s.id !== 0);

  return [...bigcrafters, ...otherSellers];
};

const getBundleData = (
  metaData: TypeMetaDatas,
): Pick<TypeBundle, 'discount' | 'selectedVariant'> => {
  const bundleVariant = metaData?.find(
    (d) => d.key === 'woosb_variant_quantity',
  );
  const bundleDiscountType = metaData?.find(
    (d) => d.key === 'woobs_variant_discount_type',
  );
  const bundleDiscountAmount = metaData?.find(
    (d) => d.key === 'woobs_variant_discount_type',
  );
  const bundleDiscountPercentage = metaData?.find(
    (d) => d.key === 'woobs_variant_discount_percentage',
  );

  return {
    selectedVariant: { quantity: Number(bundleVariant?.value) || 0 },
    discount: {
      type:
        (bundleDiscountType?.value as TypeBundle['discount']['type']) || 'none',
      amount: Number(bundleDiscountAmount?.value) || null,
      percentage: Number(bundleDiscountPercentage?.value) || null,
    },
  };
};

// TODO Formatear los precios para no tener que hacerlo en otros ficheros
// TODO Cuando en Mirakl se retornen warnings o errores mostrar toast con estos

export const cartTransformer = (cart: TypeCartGraphQL): TypeCart['cart'] => {
  if (!cart || !cart?.contents) throw Error('No cart or content detected');

  let totalItems = 0;
  const cartBySellers = new Map<number, TypeCartBySellers>();

  const miraklContents = JSON.parse(cart.cartSession);
  let isMixedCart = false;
  const edges = cart.contents.edges;

  for (const edge of edges) {
    const item = edge.node;
    const extraData = item.extraData;
    const keyBundle = extraData.find((d) => d.key === 'woosb_parent_key');
    const isMiraklProduct = item.product?.node?.metaData.some(
      (d) => d.key === 'is_mirakl_product' && d.value === 'on',
    );

    let miraklProduct: TypeMiraklProduct | undefined;

    if (isMiraklProduct) {
      isMixedCart = true;
      const offerID = item.variation?.attributes?.find(
        (attr) => attr.name === 'offer',
      )?.value;

      if (!offerID) continue;

      for (let i = 0; i < miraklContents.orders.length; ++i) {
        for (let k = 0; k < miraklContents.orders[i].offers.length; ++k) {
          if (
            miraklContents.orders[i].offers[k].offer_id ===
            Number.parseInt(offerID)
          ) {
            miraklProduct = {
              selected_shipping_type:
                miraklContents.orders[i].selected_shipping_type,
              shipping_types: miraklContents.orders[i].shipping_types,
              line_quantity: miraklContents.orders[i].offers[k].line_quantity,
              offer_id: miraklContents.orders[i].offers[k].offer_id,
              offer_quantity: miraklContents.orders[i].offers[k].offer_quantity,
              shop_name: miraklContents.orders[i].shop_name,
              shop_id: miraklContents.orders[i].shop_id,
            };
            break;
          }
        }
      }
    }

    let shippingEstimateDate;
    if (isMiraklProduct && miraklProduct) {
      const shippingType = miraklProduct.shipping_types.find(
        (shippingType) =>
          shippingType.code === miraklProduct?.selected_shipping_type.code,
      )?.total_shipping_price;

      shippingEstimateDate = {
        name: miraklProduct.selected_shipping_type.label,
        date: miraklProduct.selected_shipping_type.delivery_date,
        id: miraklProduct.selected_shipping_type.code,
        minFreeAmount: Number.parseInt(
          miraklProduct.selected_shipping_type.minFreeAmount,
        ),
        price: shippingType ?? 0,
      };
    } else {
      if (!item?.product?.node?.shippingClasses?.edges?.[0]?.node) continue;

      shippingEstimateDate = shippingEstimateDateTransformer(
        cart.shippingDates,
        item.product.node.shippingClasses.edges[0].node,
        item.product.node.caracteriSticasDelProducto.opciones
          .customDateDelivery,
      );
    }

    const sellerID =
      isMiraklProduct && miraklProduct ? miraklProduct.shop_id : 0;

    const orderID = getOrderID(shippingEstimateDate);

    if (keyBundle !== undefined) {
      const parentItem = edges.find(
        (edge) => edge.node.key === keyBundle.value,
      );

      if (
        !parentItem ||
        !parentItem?.node?.product?.node?.shippingClasses?.edges[0]?.node
      )
        continue;

      const parentOrderID = getOrderID(
        shippingEstimateDateTransformer(
          cart.shippingDates,
          parentItem.node.product.node.shippingClasses.edges[0].node,
          parentItem.node.product.node.caracteriSticasDelProducto.opciones
            .customDateDelivery,
        ),
      );

      if (cartBySellers.has(sellerID)) {
        const seller = cartBySellers.get(sellerID);

        if (!seller) continue;

        if (!(seller.orders instanceof Map)) continue;

        const order = seller.orders.get(parentOrderID);

        if (!order) continue;

        const bundle = order.offers.find(
          (offer) => offer.key === keyBundle.value,
        );

        if (!bundle) continue;

        const { discount, selectedVariant } = getBundleData(extraData);

        const offer = getOffer(miraklProduct, item);
        // Only show the price of one bundle
        const bundleItem = {
          quantity: offer.quantity / bundle.quantity,
          name: offer.product.name,
          price: toCurrency(
            (priceToNumber(offer.product.regularPrice) * offer.quantity) /
              bundle.quantity,
          ),
        };

        // Check if bundleItems has been initialized
        // it is initialized when we receive autoCalculatePrice = true
        if (
          !Array.isArray(bundle.bundleItems) ||
          !Array.isArray(bundle.bundleItemsAnalitycs)
        )
          continue;

        bundle.bundleItems.push(bundleItem);

        bundle.bundleItemsAnalitycs.push({
          product: {
            sku: offer.product.sku,
            name: offer.product.name,
            onSale: false,
            brand: offer.product.brand,
            regularPrice: '0',
            image: offer.product.image,
            link: offer.product.link,
            productCategories: offer.product.productCategories,
            customBox: true,
            customBoxVariant: selectedVariant.quantity.toString(),
          },
          quantity: bundleItem.quantity,
        });

        bundle.product.customBoxVariant = selectedVariant.quantity.toString();

        bundle.product.regularPrice = SumCurrency(
          bundle.product.regularPrice,
          toCurrency(
            (priceToNumber(offer.product.regularPrice) * offer.quantity) /
              bundle.quantity,
          ),
        );
        bundle.product.salePrice = SumCurrency(
          bundle.product.salePrice ?? 0,
          calculateDiscount(
            (priceToNumber(offer.product.regularPrice) * offer.quantity) /
              bundle.quantity,
            discount,
          ),
        );

        bundle.product.onSale = true;

        bundle.subtotal = SumCurrency(bundle.subtotal, offer.subtotal);
        bundle.subtotalTax = SumCurrency(bundle.subtotalTax, offer.subtotalTax);
        bundle.tax = SumCurrency(bundle.tax, offer.tax);
        bundle.total = SumCurrency(bundle.total, offer.total);
      }

      continue;
    }

    totalItems += item.quantity;

    if (item.product?.node?.customTemplate === 'custom-tag') {
      item.product.node = customTagTransformer(
        item.product.node,
        extraData,
      ) as TypeCartProductGraphQL;
    }

    const offer = getOffer(miraklProduct, item);

    if (cartBySellers.has(sellerID)) {
      const seller = cartBySellers.get(sellerID);

      if (!seller) continue;

      if (!(seller.orders instanceof Map)) continue;

      if (seller.orders.has(orderID)) {
        const order = seller.orders.get(orderID);

        if (!order) continue;

        const offerTotal =
          priceToNumber(offer.subtotal) + priceToNumber(offer.subtotalTax); //TODO: Esto habria que cambiarlo en el back en algun momento
        order.total += offerTotal;
        order.offers.push(offer);
        seller.total += offerTotal;
      } else {
        const order = getOrder(miraklProduct, item, shippingEstimateDate);
        seller.orders.set(orderID, order);
        seller.total += order.total;
      }
    } else {
      const order = getOrder(miraklProduct, item, shippingEstimateDate);

      cartBySellers.set(sellerID, {
        id: sellerID,
        name: getSellerName(
          isMiraklProduct && miraklProduct ? miraklProduct.shop_name : '',
        ),
        model: isMiraklProduct ? 'MARKETPLACE' : 'OPERATOR',
        orders: new Map<string, TypeCartOrders>([[orderID, order]]),
        total: order.total, // TODO Reemplazar a nivel de envio cuando se implemente esta funcionalidad en WooCommerce y Mirakl (Rodrigo)
        minFreeAmount: shippingEstimateDate.minFreeAmount ?? 0,
        shippingTotal: shippingEstimateDate.price ?? 0,
        path: (() => {
          if (!isMiraklProduct) return '/ofertas-en-cervezas';

          const sellerSlug = item.variation
            ? item.variation.node.sellerInfo.slug
            : item.product.node.sellerInfo.slug;

          return routes.sellers.seller(sellerSlug);
        })(),
      });
    }
  }

  const cartArray = Array.from(cartBySellers.values()).map((seller) => ({
    ...seller,
    orders: Array.from(seller.orders.values()),
  }));

  // TODO Eliminar
  // Esto solo se usa para analitica,
  // no hace falta guardar un objecto con todos los productos del carrito porque nosotros ya sabemos que producto ha sido añadido
  const listOfProducts: TypeCartOffer[] = cartArray.flatMap((seller) =>
    seller.orders.flatMap((order) => order.offers),
  );

  return {
    ...cart,
    contents: {
      products: listOfProducts,
      cart: isMixedCart ? order(cartArray) : cartArray,
      itemCount: totalItems,
      typeOfPurchase: getTypeOfPurchase(edges, cart?.subscriptionData),
      isMixedCart,
      edges,
    },
    ...(miraklContents && {
      warnings: miraklContents.warnings,
      errors: miraklContents.errors,
    }),
  };
};
