import { createContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';

import APClient from '@/lib/graphql/client';
import {
  addToCartMutation,
  applyCouponMutation,
  cleanCartMutation,
  removeCouponMutation,
  repeatOrderMutation,
  updateItemQuantitiesMutation,
  updateSubscriptionSchemaMutation,
} from '@/lib/graphql/mutations';
import { subscriptionPartial } from '@/lib/graphql/partials';
import { getCartQuery } from '@/lib/graphql/querys';
import { cartTransformer } from '@/lib/graphql/transformers';
import { routes } from '@/lib/routes';
import {
  debounceAsync,
  getAddedOrUpdatedItemFromCart,
  gtmPush,
  normalizeCartItems,
  normalizeEventItem,
  onlyInLeft,
} from '@/lib/utils';

import type { TypeCart } from '@/lib/graphql/types';
import type { ReactNode } from 'react';

enum CartAction {
  UPDATE_CART_CONTENT,
  UPDATE_CART_LOADING,
  UPDATE_CART_ERRORS,
}

type TypeCartAction =
  | {
      type: CartAction.UPDATE_CART_CONTENT;
      payload: {
        cart: TypeCart['cart'];
      };
    }
  | {
      type: CartAction.UPDATE_CART_LOADING;
      payload: {
        loading: boolean;
      };
    }
  | {
      type: CartAction.UPDATE_CART_ERRORS;
      payload: {
        errors: { message: string };
      };
    };

export type TypeCartDispatch = {
  getCart: () => Promise<any>;
  add: (
    productId: number,
    quantity: number,
    extraData?: { [key: string]: any }[],
  ) => Promise<any>;
  update: (productId: string | number, quantity: number) => Promise<any>;
  remove: (productId: string) => Promise<any>;
  applyCoupon: (code: string) => Promise<any>;
  removeCoupon: (code: string) => Promise<any>;
  updateSubscriptionSchema: (schema: string) => Promise<any>;
  repeatOrder: (
    products: { quantity: number; productId: number }[],
  ) => Promise<any>;
  cleanCart: () => Promise<any>;
  setCartError: (message: string) => void;
};

type TypeProductsToAdd = Map<
  string | number,
  {
    productId: string | number;
    quantity: number;
    extraData?: string;
  }
>;

const initialState: TypeCart = {
  cart: {
    appliedCoupons: [],
    availablePaymentMethods: [],
    availableShippingMethods: [],
    chosenShippingMethods: [],
    contents: {
      cart: [],
      edges: [],
      isMixedCart: false,
      itemCount: 0,
      products: [],
      typeOfPurchase: {
        discountCart: 0,
        isOnlySuscribable: false,
        isSubscription: false,
        onlySuscriptionPrice: 0,
        subscriptionPrice: 0,
        total: 0,
        totalDiscount: 0,
        uniquePrice: 0,
        withoutSubscription: 0,
      },
    },
    contentsTax: '0,000€',
    contentsTotal: '0,000€',
    discountTax: '0,000€',
    discountTotal: '0,000€',
    displayPricesIncludeTax: true,
    errors: undefined,
    feeTax: '0,000€',
    feeTotal: '0,000€',
    shippingDates: [],
    shippingTax: '0,000€',
    shippingTotal: '0,000€',
    subscriptionData: {
      discount: 0,
      discount_cart: 0,
      hasSuscription: '',
      recurring_purchase: 0,
      single_purchase: 0,
      subscription: 0,
      total: 0,
      total_discount: 0,
    },
    subtotal: '0,000€',
    subtotalTax: '0,000€',
    total: '0,000€',
    totalTax: '0,000€',
    warnings: undefined,
  },
  cartError: { message: '' },
  loading: true,
};

export const CartContext = createContext<TypeCart>(initialState);
export const CartDispatchContext = createContext<TypeCartDispatch>({
  add: async () => null,
  update: async () => null,
  remove: async () => null,
  applyCoupon: async () => null,
  removeCoupon: async () => null,
  updateSubscriptionSchema: async () => null,
  repeatOrder: async () => null,
  cleanCart: async () => null,
  setCartError: () => null,
  getCart: async () => null,
});

const reducer = (cart: TypeCart, action: TypeCartAction): TypeCart => {
  switch (action.type) {
    case CartAction.UPDATE_CART_CONTENT:
      return {
        ...cart,
        cart: action.payload.cart,
      };
    case CartAction.UPDATE_CART_LOADING:
      return {
        ...cart,
        loading: action.payload.loading,
      };
    case CartAction.UPDATE_CART_ERRORS:
      return {
        ...cart,
        cartError: {
          message: action.payload.errors.message,
        },
      };
    default: {
      throw Error(`Tipo de action desconocida: ${action}`);
    }
  }
};

const productsToAdd: TypeProductsToAdd = new Map();

const promiseStack: {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
  payload: TypeProductsToAdd;
}[] = [];

/**
 * The useMutation hook provides a prop to control the loading state but currently in this version (3.5.10) it's not working
 * so we use this ugly hack :(
 * @see https://github.com/apollographql/apollo-client/issues/9602
 */
let loading = false;

export const CartProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  /**
   * // TODO: Explore the option to add cache on the mutation/query
   * @see https://www.apollographql.com/docs/react/caching/overview
   * @see https://www.apollographql.com/docs/react/data/mutations#updating-the-cache-directly
   */
  const [addToCart] = useMutation(addToCartMutation(subscriptionPartial));

  const setCartError = (message: string) => {
    dispatch({
      type: CartAction.UPDATE_CART_ERRORS,
      payload: {
        errors: {
          message: message,
        },
      },
    });
  };

  const addToCartRecursive = () => {
    const { resolve, reject, payload } = promiseStack.shift()!; // TS fails to understand guard for Array.prototype.shift

    loading = true;

    addToCart({
      variables: {
        items: Array.from(payload.values()),
      },
    })
      .then((value) => {
        loading = false;

        const cart: TypeCart['cart'] = cartTransformer(
          value.data.addCartItems.cart,
        );

        const oldItems =
          state.cart.contents.products?.map((item) => ({
            key: item.key,
            quantity: item.quantity,
            id: item.product.databaseId,
          })) ?? [];
        const newItems =
          cart.contents.products?.map((item) => ({
            key: item.key,
            quantity: item.quantity,
            id: item.product.databaseId,
          })) ?? [];

        const diff = onlyInLeft(
          newItems,
          oldItems,
          (a, b) => a.key === b.key && a.quantity === b.quantity,
        );

        const diffWithQuantity = diff.map((item) => ({
          ...item,
          quantity: payload.get(item.id)?.quantity,
        }));

        if (cart?.errors || cart?.warnings) {
          setCartError((cart.errors || cart.warnings) ?? '');
          reject(cart.errors || cart.warnings);
        }

        dispatch({
          type: CartAction.UPDATE_CART_CONTENT,
          payload: {
            cart: cart,
          },
        });

        for (const item of diffWithQuantity) {
          const addedItem = getAddedOrUpdatedItemFromCart(
            item.key as any,
            cart,
          );

          if (addedItem.data) {
            gtmPush({
              event: 'add_to_cart',
              ecommerce: {
                items: normalizeEventItem(
                  addedItem.data.product,
                  addedItem.itemIndex,
                  item.quantity ?? 1,
                  addedItem.data.variation,
                  addedItem.data?.bundleItemsAnalitycs,
                ),
              },
              connectif: {
                items: normalizeCartItems(cart),
              },
            });
          }
        }

        if (state.cartError.message !== '') {
          setCartError('');
        }
        resolve(cart);

        if (promiseStack.length > 0) {
          addToCartRecursive();
        }
      })
      .catch((error) => {
        loading = false;
        getCart();
        setCartError(error.message);
        reject(error);
      });
  };

  const addToCartDebounce = debounceAsync(
    () =>
      new Promise((resolve, reject) => {
        promiseStack.push({ resolve, reject, payload: new Map(productsToAdd) });
        productsToAdd.clear();
        if (!loading) {
          addToCartRecursive();
        }
      }),
    800,
  );

  const add = async (
    productId: number,
    quantity: number,
    extraData?: { [key: string]: any }[],
  ) => {
    productsToAdd.set(productId, {
      productId,
      quantity,
      extraData: JSON.stringify(extraData),
    });

    // TODO: Contemplar posibilidad de devolver solo el numero de productos en el carrito y
    // mover el getCart al hover en el Toolbar, actualmente la query de getCart es demaisado
    // lenta para esto, investigar cachear la query -> https://www.apollographql.com/docs/react/data/queries#caching-query-results

    // TODO: Si un producto del array hace fallar la mutacion, todos los productos anteriores
    // a este si que se añaden al carrito, el problema reside en que la mutacion al fallar
    // no devuelve el carrito con los productos que haya podido añadir si no que devuelve un carrito nulo,
    // esto dificulta que en front podamos controlar si el carrito se ha llenado aunque sea solo hasta cierto punto o esta vacio.
    //
    // Como solucion temporal a este problema, cada vez que la mutacion de añadir al carrito falle, vamos a lanzar la query getCart()
    // para obtener el carrito y poder mostrarlo con los productos añadidos antes del fallo.

    return addToCartDebounce();
  };

  const update = async (keyCartProduct: string | number, quantity: number) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: updateItemQuantitiesMutation(subscriptionPartial),
        variables: {
          key: keyCartProduct,
          quantity: quantity,
        },
      })
        .then((value) => {
          const oldCart: TypeCart['cart'] = structuredClone(state.cart);

          const newCart: TypeCart['cart'] = cartTransformer(
            value.data.updateItemQuantities.cart,
          );

          if (newCart?.errors || newCart?.warnings) {
            setCartError((newCart?.errors ?? '') || (newCart?.warnings ?? ''));
            reject(newCart.errors || newCart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: newCart,
            },
          });

          const updatedItem = getAddedOrUpdatedItemFromCart(
            keyCartProduct as any,
            quantity !== 0 ? newCart : oldCart,
          );

          if (updatedItem.data) {
            gtmPush({
              event:
                quantity === 0
                  ? 'remove_from_cart'
                  : (oldCart?.contents.itemCount ?? 0) <
                      (newCart?.contents.itemCount ?? 0)
                    ? 'add_to_cart'
                    : 'remove_from_cart',
              ecommerce: {
                items: normalizeEventItem(
                  updatedItem.data.product,
                  updatedItem.itemIndex,
                  Math.abs(updatedItem.data.quantity - quantity) || 1,
                  updatedItem.data.variation,
                  updatedItem.data?.bundleItemsAnalitycs,
                ),
              },
              connectif: {
                items: normalizeCartItems(newCart),
              },
            });
          }

          if (state.cartError.message !== '') {
            setCartError('');
          }
          resolve(newCart);
        })
        .catch((error) => {
          setCartError(error.message);
          reject(error);
        });
    });

  const remove = async (productId: string | number) => {
    await update(productId, 0);
  };

  const applyCoupon = async (code: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: applyCouponMutation(subscriptionPartial),
        variables: {
          code: code,
        },
      })
        .then((value) => {
          const cart: TypeCart['cart'] = cartTransformer(
            value.data.applyCoupon.cart,
          );

          if (cart?.errors || cart?.warnings) {
            setCartError((cart.errors || cart.warnings) ?? '');
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          if (state.cartError.message !== '') {
            setCartError('');
          }
          resolve(cart);
        })
        .catch((error) => {
          setCartError(error.message);
          reject(error);
        });
    });

  const removeCoupon = async (code: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: removeCouponMutation(subscriptionPartial),
        variables: {
          code: code,
        },
      })
        .then((value) => {
          const cart: TypeCart['cart'] = cartTransformer(
            value.data.removeCoupons.cart,
          );

          if (cart?.errors || cart?.warnings) {
            setCartError((cart.errors || cart.warnings) ?? '');
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          if (state.cartError.message !== '') {
            setCartError('');
          }
          resolve(cart);
        })
        .catch((error) => {
          setCartError(error.message);
          reject(error);
        });
    });

  const updateSubscriptionSchema = async (schema: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: updateSubscriptionSchemaMutation,
        variables: {
          schema: schema,
        },
      })
        .then((value) => {
          const cart: TypeCart['cart'] = cartTransformer(
            value.data.updateSubscriptionSchema.cart,
          );

          if (cart?.errors || cart?.warnings) {
            setCartError((cart.errors || cart.warnings) ?? '');
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          if (state.cartError.message !== '') {
            setCartError('');
          }
          resolve(cart);
        })
        .catch((error) => {
          setCartError(error.message);
          reject(error);
        });
    });

  const cleanCart = async () =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: cleanCartMutation,
      })
        .then((value) => {
          const cart: TypeCart['cart'] = cartTransformer(
            value.data.emptyCart.cart,
          );

          if (cart?.errors || cart?.warnings) {
            setCartError((cart.errors || cart.warnings) ?? '');
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          if (state.cartError.message !== '') {
            setCartError('');
          }
          resolve(cart);
        })
        .catch((error) => {
          if (error.message === 'Cart is empty') {
            resolve(initialState);
            return;
          }

          setCartError(error.message);
          reject(error);
        });
    });

  const repeatOrder = async (
    products: { productId: number; quantity: number }[],
  ) =>
    new Promise((resolve, reject) => {
      if (products.length === 0) {
        setCartError('No se han podido añadir los artículos a la cesta');
        reject('No se han podido añadir los artículos a la cesta');
      }

      const repeatOrder = () => {
        APClient.mutate({
          mutation: repeatOrderMutation,
          variables: {
            items: products,
          },
        })
          .then((value) => {
            const cart: TypeCart['cart'] = cartTransformer(
              value.data.fillCart.cart,
            );

            if (cart?.errors || cart?.warnings) {
              setCartError((cart.errors || cart.warnings) ?? '');
              reject(cart.errors || cart.warnings);
            }

            dispatch({
              type: CartAction.UPDATE_CART_CONTENT,
              payload: {
                cart: cart,
              },
            });

            router.push(routes.cart);
            if (state.cartError.message !== '') {
              setCartError('');
            }
            resolve(cart);
          })
          .catch((error) => {
            setCartError(error.message);
            reject(error);
          });
      };

      if (state.cart.contents.itemCount > 0) {
        cleanCart()
          .then(repeatOrder)
          .catch((error) => {
            if (error.message === 'Cart is empty') {
              repeatOrder();
            } else {
              setCartError(error);
              reject(error);
            }
          });
      } else {
        repeatOrder();
      }
    });

  const getCart = async () => {
    dispatch({
      type: CartAction.UPDATE_CART_LOADING,
      payload: { loading: true },
    });

    await APClient.query({
      query: getCartQuery(subscriptionPartial),
    })
      .then((value) => {
        const cart: TypeCart['cart'] = cartTransformer(value.data.cart);

        dispatch({
          type: CartAction.UPDATE_CART_CONTENT,
          payload: {
            cart: cart,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: CartAction.UPDATE_CART_LOADING,
          payload: { loading: false },
        });
      });
  };

  return (
    <CartContext.Provider value={state}>
      <CartDispatchContext.Provider
        value={{
          add,
          remove,
          update,
          applyCoupon,
          removeCoupon,
          updateSubscriptionSchema,
          repeatOrder,
          cleanCart,
          setCartError,
          getCart,
        }}
      >
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
};
